<template>
  <div class="ma-10">
    <v-card outlined class="mb-5">
      <!-- Expansion -->
      <v-expansion-panels flat v-model="panel">
        <v-expansion-panel>
          <!-- Header -->
          <v-expansion-panel-header>
            <span class="headline">検索条件</span>
          </v-expansion-panel-header>

          <!-- Body -->
          <v-expansion-panel-content>
            <TableFilter v-model="filter" dense />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <!-- Actions -->
      <v-card-actions class="ma-2">
        <Btn
          color="success"
          icon="mdi-plus"
          @click="details('add')"
          :disabled="!adddisabled"
          >追加</Btn
        >
        <Btn
          color="error"
          icon="mdi-delete"
          :disabled="!isSelected"
          @click="clickDelete"
          >削除</Btn
        >

        <v-spacer />

        <template v-if="!$isEmpty(menus)">
          <v-btn
            v-for="(menu, i) in menus"
            :key="i"
            color="primary"
            outlined
            text
            >{{ menu.text }}</v-btn
          >
          <v-spacer />
        </template>

        <Btn color="info" icon="mdi-magnify" @click="select">検索</Btn>
      </v-card-actions>
    </v-card>

    <!-- Table -->
    <v-card>
      <v-card-title>
        <span class="text-h4">{{ title }}</span>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          label="簡易検索"
          outlined
          rounded
          dense
        ></v-text-field>
      </v-card-title>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="items"
        :search="search"
        show-select
        :item-key="itemKey"
        :items-per-page="listpage"
        multi-sort
      >
        <template v-slot:item.midnight="{ item }"
          ><v-checkbox v-model="item.midnight" readonly />
        </template>

        <template v-slot:item.actions="{ item }">
          <v-icon small @click="actions('details', item)" color="info">
            mdi-details
          </v-icon>
          <v-icon
            small
            @click="actions('edit', item)"
            class="ml-1"
            color="warning"
          >
            mdi-pencil
          </v-icon>
          <v-icon
            small
            @click="actions('delete', item)"
            class="ml-1"
            color="error"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import Common from "@/mixins/common";
import ShowDialogs from "@/mixins/showDialogs";
import Api from "@/mixins/api";
import * as Dummy from "../dummy";

const Menus = {
  worker: [{ text: "QRコード生成" }]
};

export default {
  name: "List",
  mixins: [Common, ShowDialogs, Api],
  components: {
    TableFilter: () => import("@/components/TableFilter")
  },
  props: {},
  data() {
    return {
      params: null,
      menus: [],
      selected: [],
      filter: {},
      search: "",
      open: true,
      title: "テーブル名",
      itemKey: "name",
      controls: [],
      headers: [],
      items: [],
      panel: null,
      listpage: -1,
      adddisabled: true
    };
  },
  computed: {
    isSelected() {
      return !!this.selected && this.selected.length > 0;
    }
  },
  methods: {
    // click() {},
    async details(prosess) {
      if (
        prosess === "add" &&
        (this.params.name === "shozoku-exp" || this.params.name === "shain-exp")
      ) {
        this.$warning(
          "このマスタは追加できません。\n 表示されたレコードを編集してください。"
        );
        return;
      }

      const path = "/details/" + this.params.name;
      const params = {
        title: this.title,
        prosess,
        items: this.filter.items,
        filter: this.filter
      };
      const pathname = await this.getDetailPath();
      console.log(path, params);

      this.$router.push({ name: pathname, params });
    },
    async deleteConfirm() {
      return await this.$deleteConfirm("削除します。\nよろしいですか？");
    },
    async actions(prosess, args) {
      console.log(prosess, args);

      if (prosess === "delete") {
        if (
          this.params.name == "code-group" ||
          this.params.name === "shozoku-exp" ||
          this.params.name === "shain-exp"
        ) {
          this.$warning("このデータは削除できません。");
          return;
        }
        if (await this.deleteConfirm()) {
          this.selected = [];
          this.selected.push(args);
          await this.deleteData();
          await this.$info("更新しました。");
          await this.select();
        }
      } else {
        const params = {
          title: this.title,
          prosess,
          items: this.controls,
          args,
          filter: this.filter
        };
        const pathname = await this.getDetailPath();
        this.$router.push({ name: pathname, params });
      }
    },
    async getDetailPath() {
      let pathname = "";
      switch (this.params.name) {
        case "shift-pattern":
          pathname = "ShiftPatternDetails";
          break;
        case "code-group":
          pathname = "CodeGroupDetails";
          break;
        case "code-master":
          pathname = "CodeMasterDetails";
          break;
        case "shozoku-exp":
          pathname = "ShozokuExpDetails";
          break;
        case "shain-exp":
          pathname = "ShainExpDetails";
          break;
        case "daily-event":
          pathname = "DailyEventDetails";
          break;
        case "rely-to-shain":
          pathname = "RelyToShainDetails";
          break;
        case "rely-to-shozoku":
          pathname = "RelyToShozokuDetails";
          break;
        case "shain":
          pathname = "ShainDetails";
          break;
        case "package":
          pathname = "PackageDetails";
          break;
        case "hospital":
          pathname = "HospitalDetails";
          break;
        default:
          break;
      }
      return pathname;
    },
    async clickDelete() {
      if (
        this.params.name == "code-group" ||
        this.params.name === "shozoku-exp" ||
        this.params.name === "shain-exp"
      ) {
        this.$warning("このデータは削除できません。");
        return;
      }
      this.$loading();
      try {
        if (await this.deleteConfirm()) {
          await this.deleteData();
          await this.$info("更新しました。");
          await this.select();
        }
      } catch (e) {
        console.log(e);
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
    },
    async deleteData() {
      const ids = [];
      let param = {};
      let encode = "";

      switch (this.params.name) {
        case "code-master":
          this.selected.forEach(s => {
            ids.push(s.kbn + "-" + s.code);
          });

          param = ids.join(",");
          encode = encodeURI(param);

          await this.$del(this.Paths.codeMaster, "ids=" + encode);

          break;
        case "shift-pattern":
          this.selected.forEach(s => {
            ids.push(s.shiftPatternId);
          });

          param = ids.join(",");
          encode = encodeURI(param);

          await this.$del(this.Paths.shiftpattern, "ids=" + encode);
          break;
        case "daily-event":
          this.selected.forEach(s => {
            ids.push(s.recordId);
          });

          param = ids.join(",");
          encode = encodeURI(param);

          await this.$del(this.Paths.dailyEvent, "ids=" + encode);
          break;
        case "rely-to-shain":
          this.selected.forEach(s => {
            ids.push(s.relyCode);
          });

          param = ids.join(",");
          encode = encodeURI(param);

          await this.$del(this.Paths.relyToShain, "ids=" + encode);
          break;
        case "rely-to-shozoku":
          this.selected.forEach(s => {
            ids.push(s.relyCode);
          });

          param = ids.join(",");
          encode = encodeURI(param);

          await this.$del(this.Paths.relyToShozoku, "ids=" + encode);
          break;

        case "shain":
          this.selected.forEach(s => {
            ids.push(s.userId);
          });

          param = ids.join(",");
          encode = encodeURI(param);

          await this.$del(this.Paths.shain, "ids=" + encode);
          break;

        case "package":
          this.selected.forEach(s => {
            ids.push(s.hojinCd + "-" + s.hospitalCd + "-" + s.packageNo);
          });

          param = ids.join(",");
          encode = encodeURI(param);

          await this.$del(this.Paths.package, "ids=" + encode);

          break;

        case "hospital":
          this.selected.forEach(s => {
            ids.push(s.hojinCd + "-" + s.hospitalCd);
          });

          param = ids.join(",");
          encode = encodeURI(param);

          await this.$del(this.Paths.hospital, "ids=" + encode);

          break;

        default:
          break;
      }
      await this.select();
    },
    async getdata(path) {
      this.$loading();
      try {
        return await this.$get(path);
      } catch (e) {
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
    },
    async select() {
      console.log("select");
      console.log("filter", this.filter.values);
      let data;
      // this.$loading();
      try {
        switch (this.params.name) {
          case "shift-pattern":
            if (
              typeof this.filter.values !== "undefined" &&
              Object.keys(this.filter.values).length
            ) {
              const encode = encodeURI(JSON.stringify(this.filter.values));
              console.log("encode", encode);
              data = await this.$get(
                this.Paths.shiftpattern,
                "query=" + encode
              );
            } else {
              data = await this.getdata(this.Paths.shiftpattern);
            }
            break;
          case "code-group":
            if (
              typeof this.filter.values !== "undefined" &&
              Object.keys(this.filter.values).length
            ) {
              const encode = encodeURI(JSON.stringify(this.filter.values));
              console.log("encode", encode);
              data = await this.$get(this.Paths.codeGroup, "query=" + encode);
            } else {
              data = await this.getdata(this.Paths.codeGroup);
            }
            break;
          case "code-master":
            if (
              typeof this.filter.values !== "undefined" &&
              Object.keys(this.filter.values).length
            ) {
              const encode = encodeURI(JSON.stringify(this.filter.values));
              console.log("encode", encode);
              data = await this.$get(this.Paths.codeMaster, "query=" + encode);
            } else {
              data = await this.getdata(this.Paths.codeMaster);
            }
            break;
          case "shozoku-exp":
            if (
              typeof this.filter.values !== "undefined" &&
              Object.keys(this.filter.values).length
            ) {
              const encode = encodeURI(JSON.stringify(this.filter.values));
              console.log("encode", encode);
              data = await this.$get(this.Paths.shozokuExp, "query=" + encode);
            } else {
              data = await this.getdata(this.Paths.shozokuExp);
            }
            break;
          case "shain-exp":
            if (
              typeof this.filter.values !== "undefined" &&
              Object.keys(this.filter.values).length
            ) {
              const encode = encodeURI(JSON.stringify(this.filter.values));
              console.log("encode", encode);
              data = await this.$get(this.Paths.shainExp, "query=" + encode);
            } else {
              data = await this.getdata(this.Paths.shainExp);
            }
            break;
          case "daily-event":
            if (
              typeof this.filter.values !== "undefined" &&
              Object.keys(this.filter.values).length
            ) {
              const encode = encodeURI(JSON.stringify(this.filter.values));
              console.log("encode", encode);
              data = await this.$get(this.Paths.dailyEvent, "query=" + encode);
            } else {
              data = await this.getdata(this.Paths.dailyEvent);
            }
            break;
          case "rely-to-shain":
            if (
              typeof this.filter.values !== "undefined" &&
              Object.keys(this.filter.values).length
            ) {
              const encode = encodeURI(JSON.stringify(this.filter.values));
              console.log("encode", encode);
              data = await this.$get(this.Paths.relyToShain, "query=" + encode);
            } else {
              data = await this.getdata(this.Paths.relyToShain);
            }
            break;
          case "rely-to-shozoku":
            if (
              typeof this.filter.values !== "undefined" &&
              Object.keys(this.filter.values).length
            ) {
              const encode = encodeURI(JSON.stringify(this.filter.values));
              console.log("encode", encode);
              data = await this.$get(
                this.Paths.relyToShozoku,
                "query=" + encode
              );
            } else {
              data = await this.getdata(this.Paths.relyToShozoku);
            }
            break;

          case "shain":
            if (
              typeof this.filter.values !== "undefined" &&
              Object.keys(this.filter.values).length
            ) {
              const encode = encodeURI(JSON.stringify(this.filter.values));
              console.log("encode", encode);

              data = await this.$get(this.Paths.shain, "query=" + encode);
            } else {
              data = await this.getdata(this.Paths.shain);
            }
            break;
          case "package":
            if (
              typeof this.filter.values !== "undefined" &&
              Object.keys(this.filter.values).length
            ) {
              const encode = encodeURI(JSON.stringify(this.filter.values));
              console.log("encode", encode);

              data = await this.$get(this.Paths.package, "query=" + encode);
            } else {
              data = await this.getdata(this.Paths.package);
            }
            break;

          case "hospital":
            if (
              typeof this.filter.values !== "undefined" &&
              Object.keys(this.filter.values).length
            ) {
              const encode = encodeURI(JSON.stringify(this.filter.values));
              console.log("encode", encode);

              data = await this.$get(this.Paths.hospital, "query=" + encode);
            } else {
              data = await this.getdata(this.Paths.hospital);
            }
            break;

          default:
            break;
        }
        await this.setdata(data);
      } catch (e) {
        console.log(e);
        this.$error(e.message);
      }
    },
    async update() {
      console.log("==update");

      const params = this.$route.params;
      this.params = params;
      console.log("params", params);

      this.menus = Menus[params.name];

      // Get Data
      let data = null;
      let path = "";
      this.listpage = -1;
      switch (params.name) {
        case "shift-pattern":
          console.log("shift pattern");
          path = this.Paths.shiftpattern;
          break;
        case "code-group":
          console.log("code-group");
          path = this.Paths.codeGroup;
          break;
        case "code-master":
          console.log("code-master");
          this.listpage = 15;
          path = this.Paths.codeMaster;
          break;
        case "shozoku-exp":
          console.log("shozoku-exp");
          path = this.Paths.shozokuExp;
          break;
        case "shain-exp":
          console.log("shain-exp");
          path = this.Paths.shainExp;
          break;
        case "daily-event":
          console.log("daily-event");
          path = this.Paths.dailyEvent;
          break;
        case "rely-to-shain":
          console.log("rely-to-shain");
          path = this.Paths.relyToShain;
          break;
        case "rely-to-shozoku":
          console.log("rely-to-shozoku");
          path = this.Paths.relyToShozoku;
          break;
        case "shain":
          console.log("shain");
          path = this.Paths.shain;
          break;
        case "package":
          console.log("package");
          path = this.Paths.package;
          break;
        case "hospital":
          console.log("hospital");
          path = this.Paths.hospital;
          break;

        default:
          data = Dummy.getDefaultTable();
          break;
      }
      data = await this.getdata(path);

      const authority = [9];
      authority[0] = this.$store.state.authority;
      this.adddisabled = true;
      if (params.name == "shain" && authority[0] < 3) {
        this.adddisabled = false;
      }

      console.log(data);
      await this.setdata(data);
    },
    async setdata(data) {
      this.selected = [];
      this.headers = data.headers;
      this.headers.push({ text: "actions", value: "actions" });
      this.items = data.items;
      this.title = data.name;
      this.itemKey = data.key;

      let controls = null;

      if (data.controls) {
        controls = data.controls;
        controls.forEach(control => {
          if (!control.type) control.type = typeof this.items[0][control.name];
        });
      } else {
        controls = [];
        this.headers
          .filter(e => e.value !== "actions")
          .forEach(head => {
            controls.push({
              label: head.text,
              name: head.value,
              type: typeof this.items[0][head.value]
            });
          });
      }
      this.controls = controls;

      console.log("this.filter", this.filter);
      if ("items" in this.filter) {
        console.log("items in the filter");
      } else {
        this.$set(this.filter, "items", controls); //オブジェクトへの代入は変更を検出できないため、強制発火
      }
    }
  },
  watch: {
    $route(to, from) {
      console.log("watch.$route", to, from);
      this.filter = {};
      this.panel = null;
      this.update();
    }
  },
  async created() {
    console.log("==created");
    this.update();

    console.log("query", this.$route.query);
    if ("filter" in this.$route.query) {
      this.filter = this.$route.query.filter;
      if (
        typeof this.filter.values !== "undefined" &&
        Object.keys(this.filter.values).length
      ) {
        this.panel = 0;
        await this.select();
      }
    }
  },
  mounted() {
    console.log("==mounted");
  },
  beforeUpdate() {
    console.log("==beforeUpdate");
  },
  updated() {
    console.log("==updated");
  }
};
</script>
